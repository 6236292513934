import axios from 'axios';
import http from 'http';
import https from 'https';
import axiosRetry from 'axios-retry';
import { log } from './log';

// 10 seconds
const DEFAULT_TIMEOUT = 1000 * 10;

const axiosWithRetry = axios.create({
    timeout: DEFAULT_TIMEOUT,
    httpAgent: new http.Agent({
        keepAlive: true,
        keepAliveMsecs: 1000 * 30,
        maxFreeSockets: 50,
        timeout: DEFAULT_TIMEOUT,
    }),
    httpsAgent: new https.Agent({
        keepAlive: true,
        keepAliveMsecs: 1000 * 30,
        maxFreeSockets: 50,
        timeout: DEFAULT_TIMEOUT,
    }),
});

axiosRetry(axiosWithRetry, {
    retries: 5,
    shouldResetTimeout: true,
    retryCondition() {
        return true;
    },
    retryDelay(retryCount, error) {
        log(
            JSON.stringify({
                message: `[axios] Failed to fetch, going to retry. This is retry attempt ${retryCount}`,
                error: {
                    response: error.response?.data,
                    code: error.code,
                    message: error.message,
                    stack: error.stack,
                },
            }),
            true
        );
        return 0;
    },
});

if (typeof window === 'undefined') {
    const { registerInterceptor } = require('axios-cached-dns-resolve');
    registerInterceptor(axiosWithRetry);
}

export { axiosWithRetry };
