import { fetchFromGlobalCMS } from 'lib/fetch-from-cms';
import { getStaleWhileRefresh } from 'lib/memory-cache';
import {
    Maybe,
    ContentBlock,
    Post,
    Page,
    Scalars,
    RootQueryToCategoryConnection,
    CategoryToAncestorsCategoryConnection,
    CategoryToAncestorsCategoryConnectionEdge,
} from 'interfaces/microsite-cms';
import {
    getArticlePageByUrlScheme,
    getCategoryPageByUrlScheme,
    getIndexPageByUrlScheme,
    getSiteMapScheme,
    getPagePreviewById,
    getRecentCategoryPostsScheme,
} from 'lib/schemes';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { SupportedLocales } from 'lib/supported-locales';

export const PAGE_NOT_FOUND_PROPS = {
    title: null,
    description: null,
    blocks: null,
};

type PageType = {
    language: {
        locale: SupportedLocales;
    };
    seo?: {
        title?: string;
        metaDesc?: string;
        // @TODO: add support in CMS
        metaKeywords?: string;
        metaRobotsNoindex?: string;
        metaRobotsNofollow?: string;
        opengraphPublishedTime?: string;
        opengraphModifiedTime?: string;
    };
    databaseId: Scalars['Int'];
    title: string;
    categories?: Maybe<RootQueryToCategoryConnection>;
    contentBlocks: {
        blocks: Maybe<ContentBlock>[];
    };
    featuredImage?: {
        node?: {
            altText?: Maybe<Scalars['String']>;
            caption?: Maybe<Scalars['String']>;
            mediaDetails?: {
                height?: Maybe<Scalars['String']>;
                width?: Maybe<Scalars['String']>;
            };
            mediaItemUrl?: Maybe<Scalars['String']>;
            mimeType?: Maybe<Scalars['String']>;
            srcSet?: Maybe<Scalars['String']>;
            sourceUrl?: Maybe<Scalars['String']>;
        };
    };
    translations?: Page['translations'] | Post['translations'];
};

type Data = {
    nodeByUri?: PageType;
    page?: PageType;
    categories?: Maybe<RootQueryToCategoryConnection>;
};

function populatePagePropsDataFromServer(
    data: Data['nodeByUri'] | Data['page'],
    categories?: Data['categories']
) {
    if (!data || data === null) {
        return PAGE_NOT_FOUND_PROPS;
    }

    return {
        title: data.title || data.seo?.title || null,
        description: data?.seo?.metaDesc || null,
        categories: data?.categories || null,
        blocks: data.contentBlocks?.blocks || null,
        imageUrl: data.featuredImage?.node?.mediaItemUrl || null,
        imageAlt: data.featuredImage?.node?.altText || null,
        imageWidth: data.featuredImage?.node?.mediaDetails?.width || null,
        imageHeight: data.featuredImage?.node?.mediaDetails?.height || null,
        seoTitle: data.seo?.title,
        seoDescription: data.seo?.metaDesc,
        metaKeywords: data.seo?.metaKeywords,
        metaRobotsNoindex: data.seo?.metaRobotsNoindex,
        metaRobotsNofollow: data.seo?.metaRobotsNofollow,
        opengraphPublishedTime: data.seo?.opengraphPublishedTime,
        opengraphModifiedTime: data.seo?.opengraphModifiedTime,
        pageCategoriesList: categories || null,
    };
}

function populateArticlePagePropsDataFromServer(data: Data['nodeByUri']) {
    if (!data || data === null) {
        return PAGE_NOT_FOUND_PROPS;
    }

    return {
        id: data.databaseId || 0,
        title: data.title || data.seo?.title || null,
        description: data?.seo?.metaDesc || null,
        categories: data?.categories || null,
        blocks: data.contentBlocks?.blocks || null,
        imageUrl: data.featuredImage?.node?.mediaItemUrl || null,
        imageAlt: data.featuredImage?.node?.altText || null,
        imageWidth: data.featuredImage?.node?.mediaDetails?.width || null,
        imageHeight: data.featuredImage?.node?.mediaDetails?.height || null,
        seoTitle: data.seo?.title,
        seoDescription: data.seo?.metaDesc,
        metaKeywords: data.seo?.metaKeywords,
        metaRobotsNoindex: data.seo?.metaRobotsNoindex,
        metaRobotsNofollow: data.seo?.metaRobotsNofollow,
        opengraphPublishedTime: data.seo?.opengraphPublishedTime,
        opengraphModifiedTime: data.seo?.opengraphModifiedTime,
        // @ts-ignore
        footerCtaText: data?.postFieldGroup?.footerCtaText,
        // @ts-ignore
        footerCtaUrl: data?.postFieldGroup?.footerCtaUrl,
        pageCategoriesList: data?.categories || null,
    };
}

type getPageByUrlType = Promise<{
    id?: Scalars['Int'];
    title: string | null;
    description: string | null;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: Maybe<RootQueryToCategoryConnection>;
    blocks: Maybe<ContentBlock>[] | null;
    imageUrl?: string | null;
    imageAlt?: string | null;
    imageHeight?: string | null;
    imageWidth?: string | null;
    seoTitle?: string | null;
    seoDescription?: string | null;
    metaDesc?: string;
    metaKeywords?: string;
    metaRobotsNoindex?: string;
    metaRobotsNofollow?: string;
    opengraphPublishedTime?: string;
    opengraphModifiedTime?: string;
}>;

export async function getArticlePageByUrl(uri: string, lang: SupportedLanguage): getPageByUrlType {
    return getStaleWhileRefresh({
        /**
         * Requests are cached by key
         */
        key: { id: `getArticlePageByUrlIn${lang}`, uri },
        fetch: async () => {
            const data: Data = await fetchFromGlobalCMS(getArticlePageByUrlScheme, {
                variables: {
                    uri,
                },
            });
            const language = SupportedLocales[lang] || SupportedLocales.en;

            /**
             * language is not supported on BE corner-case, add error handler
             */
            if (!language) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * no data corner-case, add error handler
             */
            if (!data) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * case for default language
             */
            return populateArticlePagePropsDataFromServer(data.nodeByUri);
        },
    });
}

export async function getCategoryPageByUrl(
    url: string,
    lang: SupportedLanguage
    // pathUrl: string
): getPageByUrlType {
    return getStaleWhileRefresh({
        /**
         * Requests are cached by key
         */
        key: { id: `getCategoryPageByUrlIn${lang}`, url },
        fetch: async () => {
            const data: Data = await fetchFromGlobalCMS(getCategoryPageByUrlScheme, {
                variables: {
                    url,
                    // categoryNamesList: `["${pathUrl.replaceAll('/', '')}"]`
                },
            });
            const language = SupportedLocales[lang] || SupportedLocales.en;
            /**
             * language is not supported on BE corner-case, add error handler
             */
            if (!language) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * no data corner-case, add error handler
             */
            if (!data) {
                return PAGE_NOT_FOUND_PROPS;
            }
            /**
             * case for default language
             */
            return populatePagePropsDataFromServer(data.nodeByUri, data.categories);
        },
    });
}

export async function getIndexPage(lang: SupportedLanguage): getPageByUrlType {
    return getStaleWhileRefresh({
        /**
         * Requests are cached by key
         */
        key: { id: `getIndexPageByLang${lang}` },
        fetch: async () => {
            const data: Data = await fetchFromGlobalCMS(getIndexPageByUrlScheme);
            /**
             * for now we would like to set one language
             */
            // @ts-ignore
            const language = SupportedLocales[lang] || SupportedLocales.en;

            /**
             * language is not supported on BE corner-case, add error handler
             */
            if (!language) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * no data corner-case, add error handler
             */
            if (!data) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * case for default language
             */
            return populatePagePropsDataFromServer(data.nodeByUri, data.categories);
        },
    });
}

export type SiteMapItem = {
    uri: string;
    slug: string;
    modified: string;
    contentBlocks: {
        blocks: Maybe<ContentBlock>[];
    };
};

export type SiteMapList = SiteMapItem[];

export type ServerSiteMap = {
    pages: {
        nodes: SiteMapList;
    };
    posts: {
        nodes: SiteMapList;
    };
};

export async function getServerSiteMap() {
    return getStaleWhileRefresh({
        key: { id: `getSiteMap$` },
        fetch: async () => {
            const data: ServerSiteMap = await fetchFromGlobalCMS(getSiteMapScheme);

            return [data.pages.nodes, data.posts.nodes].flat();
        },
    });
}

export async function getInternalPagePreviewById(
    pageId: string,
    asPreview: boolean
): getPageByUrlType {
    /**
     * trick to invalidate cache of requests on FE side
     * only for preview, not for Production requests
     */
    const timestamp = new Date().getTime();
    return getStaleWhileRefresh({
        /**
         * Requests are cached by key
         */
        key: { id: `getPagePreviewById${pageId}-timestamp${timestamp}`, pageId },
        fetch: async () => {
            const data: Data = await fetchFromGlobalCMS(getPagePreviewById, {
                variables: {
                    id: pageId,
                    asPreview,
                },
            });

            /**
             * no data corner-case, add error handler
             */
            if (!data) {
                return PAGE_NOT_FOUND_PROPS;
            }

            /**
             * case for default language
             */
            return populatePagePropsDataFromServer(data.page);
        },
    });
}

export type Categories = 'Fall' | 'Winter' | 'Spring' | 'Summer' | 'Uncategorized';

export async function getRecentCategoryPosts(
    categoryName: Categories | null,
    count = 4,
    after = null as string | null,
    tagSlugNotIn = null as string | null
): Promise<{
    edges?: Maybe<Maybe<CategoryToAncestorsCategoryConnectionEdge>[]> | undefined;
    hasNextPage: boolean;
    endCursor: string;
}> {
    return getStaleWhileRefresh({
        key: { id: 'getRecentCategoryPosts', categoryName, count, after },
        fetch: async () => {
            const data: {
                categories: CategoryToAncestorsCategoryConnection;
            } = await fetchFromGlobalCMS(getRecentCategoryPostsScheme, {
                variables: {
                    categoryName,
                    first: count,
                    after,
                    tagSlugNotIn,
                },
            });

            const posts = data.categories.edges?.[0]?.node?.posts;

            return {
                edges: data.categories.edges || undefined,
                endCursor: posts?.pageInfo?.endCursor || '',
                hasNextPage: Boolean(posts?.pageInfo?.hasNextPage),
            };
        },
    });
}
