import { axiosWithRetry } from 'lib/axios-with-retry';

type Headers = {
    'Content-Type': string;
    Authorization?: string;
    'X-Query-Name': string;
};

// Removes unnecessary spaces from query to make it easier to read in the logs
function trimQuery(query: string) {
    return query.replace(/(\s|\n)+/g, ' ');
}

async function fetchFromCms(
    apiUrl: string,
    authToken: string | undefined,
    query: string,
    { variables } = {} as { variables: Record<string, any> }
) {
    // Try to get the query so we can pass to access logs
    const queryName = query.match(/query(\s+)(\w+)/)?.[2] || '';

    const headers: Headers = { 'Content-Type': 'application/json', 'X-Query-Name': queryName };

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    const response = await axiosWithRetry.post(
        apiUrl,
        JSON.stringify({
            query: trimQuery(query),
            variables,
        }),
        {
            headers,
            transformResponse: (responseString: string) => {
                /**
                 * please refer to nginx config
                 */
                // basePath location for images
                // media - video/images
                // return updateImagePathsRegExp(responseString);
                return responseString.replace(
                    /http:\\\/\\\/microsite-cms-ceph\.mlan:7480\\\//g,
                    'http://cdn-internal:8081/'
                );
            },
        }
    );

    const text = await response.data;
    const json = JSON.parse(text);

    if (json && json.errors) {
        /* eslint-disable no-console */
        console.error(JSON.stringify(json.errors, null, 4));
        throw new Error('Failed to fetch API');
    }

    return json.data;
}

export async function fetchFromGlobalCMS(
    query: string,
    vars = {} as { variables: Record<string, any> }
) {
    return fetchFromCms(
        process.env.MICROSITES_CMS_API_URL as string,
        process.env.MICROSITES_CMS_AUTH_REFRESH_TOKEN as string,
        query,
        vars
    );
}
